import { useEffect, useState } from "react";
import DataTableFilter from "./filter/DataTableFilter";
import DataTablePagination from "./pagination/DataTablePagination";
import translator from "../Translation/Translate";
import Filter, { FilterColumn } from "../Filter/Filter";

export interface DataTableColums {
    index: string;
    title: string;
    content: (data: any) => React.ReactNode;
    sort?: () => void;
    filter?: boolean;
    order?: boolean;
    className?: string;
    headerFilter?: boolean;
}
interface DataTableProps {
    columns: DataTableColums[];
    data: any[];
    pageLength?: number;
    className?: string;
    tableButton?: React.ReactNode;
    handlePaginationChange?: () => void;
    addParrain?: boolean;
    pagination?: boolean;
}

const DataTableHeader = ({ columns }: { columns: DataTableColums[] }) => {
    const { translate } = translator();
    const [filterIndex, setFilterIndex] = useState<number>(0);
    const [filterOrder, setFilterOrder] = useState<"ASC" | "DESC">("ASC");
    return (
        <thead className="thead-mobil">
            <tr>
                {columns.map((column: DataTableColums, i: number) => {
                    return (
                        <th className={column.className} key={`th-${i}`}>
                            <div className="flex">
                                <span className="mr-2">
                                    {translate(".", column.title)}
                                </span>
                                {column.order && (
                                    <FilterColumn
                                        value={false}
                                        changeValue={(value: boolean) => {
                                            setFilterIndex(i);
                                            setFilterOrder(
                                                value ? "ASC" : "DESC",
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

const DatatableBody = ({
    data,
    columns,
}: {
    data: any;
    columns: DataTableColums[];
}) => {
    const { translate } = translator();
    return (
        <tbody className="tbody-mobil">
            {data.length === 0 && (
                <tr>
                    <td colSpan={columns.length} className="text-center">
                        Rien à afficher{" "}
                    </td>
                </tr>
            )}
            {data.map((item: any, i: number) => {
                return (
                    <tr key={`tr-${i}`} className="data-tbody">
                        {columns.map((column, index) => (
                            <td
                                key={`td-${index}`}
                                data-label={translate(".", column.title)}
                            >
                                {" "}
                                {column.content(item)}
                            </td>
                        ))}
                    </tr>
                );
            })}
        </tbody>
    );
};

const DataTable = ({
    columns,
    data,
    pageLength,
    className,
    tableButton,
    handlePaginationChange,
    addParrain,
    pagination = true,
}: DataTableProps) => {
    const [perPage, setPerPage] = useState<number>(
        pageLength ? pageLength : 10,
    );

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentData, setCurrentData] = useState<any[]>([]);
    const [tempData, setTempData] = useState<any[]>(data);

    useEffect(() => {
        setTempData(data);
    }, [data]);

    useEffect(() => {
        const start = currentPage * perPage;
        try {
            const temp = tempData.slice(start, start + perPage);
            setCurrentData(temp);
        } catch {
            console.log({
                tempData,
            });
        }
    }, [perPage, tempData, currentPage]);

    return (
        <>
            {pagination && (
                <DataTableFilter
                    currentData={currentData}
                    setTempData={setTempData}
                    data={data}
                    columns={columns}
                    setPerPage={setPerPage}
                    tableButton={tableButton}
                    perPage={perPage}
                    addParrain={addParrain}
                />
            )}

            <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <table className={className}>
                    <DataTableHeader columns={columns} />
                    <DatatableBody data={currentData} columns={columns} />
                </table>
            </div>
            {pagination && (
                <DataTablePagination
                    setPerPage={setPerPage}
                    perPage={perPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    tempData={tempData}
                    handlePaginationChange={handlePaginationChange}
                />
            )}
        </>
    );
};

export default DataTable;
