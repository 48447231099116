import { LogIn, User2, UserPlus2 } from "lucide-react";
import { NavLink } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import DropdownLanguage from "../../Front/Components/Theme/DropdownLanguage";
import ThemeToggle from "../../Front/Components/Theme/ThemeToggle";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import RoutesNames from "../../Services/RoutesNames";
import UtilsService from "../../Services/UtilsService";

const Header = () => {
    const { isAuthenticated } = useAuthentication();
    const { translate } = translator();

    return (
        <div className="top-bar mdg-nav-bar mdg-theme-nav !inline-flex !fixed bg-black w-full !border-0 z-[10000]">
            <nav
                aria-label="breadcrumb"
                className="-intro-x mr-auto  sm:flex w-full"
            >
                <div className="p-4 sm:w-64">
                    <NavLink
                        to="/"
                        className="active mdg-logo mx-auto !mt-[-10px] flex h-10 w-full justify-center text-orange-500 hover:text-yellow-500"
                        aria-current="page"
                    >
                        <img
                            src={UtilsService.getPulicImage(
                                "/dist/image/Logo-Kalypay-HD-Original-PNG.png",
                            )}
                            className="h-[50px] md:h-[50px] w-auto"
                            alt="Kalypay Logo"
                        />
                    </NavLink>
                </div>
            </nav>
            <div className="flex pr-[10px] md:!pr-[0px] w-full justify-end">
                {isAuthenticated ? (
                    <div className="intro-x relative mr-2 ">
                        <NavLink
                            className="btn-sm  items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm hidden md:!inline-flex"
                            to={RoutesNames.Assets}
                        >
                            <div className="hidden md:flex">
                                {translate("GENERAL", "MY_ACCOUNT")}
                                <span className="tracking-normal text-[#EEA913] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2 pt-[3px]">
                                    <svg
                                        className="fill-current"
                                        width="12"
                                        height="10"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                    </svg>
                                </span>
                            </div>
                        </NavLink>
                        <NavLink
                            to={RoutesNames.Assets}
                            className="flex md:hidden w-8 h-8 flex items-center justify-center hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full bg-slate-200"
                        >
                            <User2 />
                        </NavLink>
                    </div>
                ) : (
                    <>
                        <div className="intro-x relative mr-2 ">
                            <NavLink
                                className="btn-sm ml-2 hidden md:!inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm"
                                to={RoutesNames.Register}
                            >
                                {translate("GENERAL", "SIGNUP")}
                            </NavLink>
                            <NavLink
                                to={RoutesNames.Register}
                                className="flex md:hidden w-8 h-8 flex items-center justify-center hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full bg-slate-200"
                            >
                                <UserPlus2 />
                            </NavLink>
                        </div>
                        <div className="intro-x relative mr-2 ">
                            <NavLink
                                className="btn-sm hidden md:!inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm"
                                to={RoutesNames.Login}
                            >
                                {translate("GENERAL", "SIGNIN")}
                            </NavLink>
                            <NavLink
                                to={RoutesNames.Login}
                                className="flex md:hidden w-8 h-8 flex items-center justify-center hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full bg-slate-200"
                            >
                                <LogIn />
                            </NavLink>
                        </div>
                    </>
                )}
                <div className="intro-x relative mr-2  language">
                    <DropdownLanguage align="left" />
                </div>
                <div className="flex items-center justify-end">
                    <div className="intro-x dropdown mr-2 flex h-7 w-5 items-center">
                        <ThemeToggle />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
