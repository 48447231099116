import loadable from "@loadable/component";
import PrivateRouteProvider from "../../Routes/RouteProvider/PrivateRouteProvider";
import EcosystemBase from "../Pages/ecosytem/EcosystemBase";
import SettingBase from "../Pages/setting/setting.base";
import MosaicBase from "../layout/MosaicBase";

import RoutesNames from "../../Services/RoutesNames";
import Commercial from "../Pages/users/commercial/Commercial";
import Moderations from "../Pages/Moderation/Moderations";
import AccountingBase from "../Pages/accounting/AccountingBase";
import ListClientUser from "../Pages/users/client/ListClientUser";
import ColdWalletDetail from "../Pages/cold-wallet/ColdWallet";
import EcosystemItem from "../Pages/ecosytem/EcosystemItem";
import Payment from "../Pages/payment/Payment";
import PointOfSale from "../Pages/users/point-of-sale/PointOfSale";
import ProfilForm from "../Pages/users/profil/ProfilForm";
import SalesManager from "../Pages/users/sales-manager/SalesManager";
import MailModerations from "../Pages/moderation-mail/Moderations";
import Borrowing from "../Pages/borrowing/Borrowing";

const Dashboard = loadable(() => import("../Pages/dashboard/Dashboard"));
const Transactions = loadable(
    () => import("../Pages/transactions/Transactions"),
);

const Assets = loadable(() => import("../Pages/assets/Assets"));
const Deposit = loadable(() => import("../Pages/assets/deposit/Deposit"));
const Sell = loadable(() => import("../Pages/assets/sell/Sell"));
const Exchange = loadable(() => import("../Pages/assets/exchange/Exchange"));
const Buy = loadable(() => import("../Pages/assets/buy/Buy"));
const Transfert = loadable(() => import("../Pages/assets/transfert/Transfert"));
const TransfertIntern = loadable(
    () => import("../Pages/assets/transfert/InternalTransfert"),
);
const TransfertExtern = loadable(
    () => import("../Pages/assets/transfert/ExternalTransfert"),
);
const TransfertMass = loadable(
    () => import("../Pages/assets/transfert/MassTransfert"),
);
const Card = loadable(() => import("../Pages/card/Card"));
const FindAgent = loadable(() => import("../Pages/find-agent/FindAgent"));

const Referral = loadable(() => import("../Pages/setting/affiliation"));
const Security = loadable(() => import("../Pages/setting/security"));
const General = loadable(() => import("../Pages/setting/general"));
const Position = loadable(() => import("../Pages/setting/position"));
const CardOrder = loadable(() => import("../Pages/card-order/CardOrders"));

const ApplicationWeb = loadable(
    () => import("../Pages/setting/application-web"),
);
const QrCodeMerchant = loadable(
    () => import("../Pages/setting/qr-code-merchant"),
);

const Balances = loadable(() => import("../Pages/accounting/Balances"));
const DepositVirementFA = loadable(
    () => import("../Pages/deposit-virement-fa/DepositVirementFA"),
);

const Earn = loadable(() => import("../Pages/Earn/Earn"));

const Services = loadable(() => import("../Pages/services/Services"));
const Partner = loadable(() => import("../Pages/partners/Partner"));
const LoyaltyAndAdvantage = loadable(
    () => import("../Pages/loyalty/LoyaltyAndAdvantage"),
);
const BusinessDev = loadable(
    () => import("../Pages/users/business-dev/BusinessDev"),
);

const BloquedUsers = loadable(
    () => import("../Pages/users/blocked-user/ListBlockedUser"),
);
const TransactionModeration = loadable(
    () => import("../Pages/transaction-relance/TransactionRelanceList"),
);

const KalypayRoutes = [
    {
        element: <PrivateRouteProvider />,
        children: [
            {
                element: <MosaicBase />,
                children: [
                    {
                        path: "/",
                        children: [
                            {
                                path: RoutesNames.Dashboard,
                                element: <Dashboard />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Assets,
                                children: [
                                    {
                                        index: true,
                                        element: <Assets />,
                                    },
                                    {
                                        path: "deposit",
                                        element: <Deposit />,
                                    },
                                    {
                                        path: "buy",
                                        element: <Buy />,
                                    },
                                    {
                                        path: "sell",
                                        element: <Sell />,
                                    },
                                    {
                                        path: "exchange",
                                        element: <Exchange />,
                                    },
                                    {
                                        path: "transfert",
                                        element: <Transfert />,
                                        children: [
                                            {
                                                path: "intern",
                                                element: <TransfertIntern />,
                                            },
                                            {
                                                path: "extern",
                                                element: <TransfertExtern />,
                                            },
                                            {
                                                path: "mass",
                                                element: <TransfertMass />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: RoutesNames.Transactions,
                                element: <Transactions />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Customer,
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <ListClientUser />,
                                    },
                                ],
                            },
                            {
                                path: RoutesNames.Payment,
                                element: <Payment />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Cards,
                                element: <Card />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Lending,
                                element: <Earn />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Nodes,
                                element: <Earn type="node" />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Trading,
                                element: <Earn type="trading" />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Services,
                                element: <Services />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Loaylty,
                                element: <LoyaltyAndAdvantage />,
                                index: true,
                            },
                            {
                                path: RoutesNames.FindAgent,
                                element: <FindAgent />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Setting.Index,
                                element: <SettingBase />,
                                children: [
                                    {
                                        path: RoutesNames.Setting.General,
                                        element: <General />,
                                    },
                                    {
                                        path: RoutesNames.Setting.Security,
                                        element: <Security />,
                                    },
                                    {
                                        path: RoutesNames.Setting.Referrals,
                                        element: <Referral />,
                                    },
                                    {
                                        path: RoutesNames.Setting.Position,
                                        element: <Position />,
                                    },
                                    {
                                        path: RoutesNames.Setting
                                            .Qr_code_merchant,
                                        element: <QrCodeMerchant />,
                                    },
                                    {
                                        path: RoutesNames.Setting
                                            .Aplications_web,
                                        element: <ApplicationWeb />,
                                    },
                                ],
                            },
                            {
                                path: RoutesNames.CardOrder,
                                element: <CardOrder />,
                                index: true,
                            },
                            {
                                path: RoutesNames.DepositVirementFA,
                                element: <DepositVirementFA />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Ecosystem.Index,
                                element: <EcosystemBase />,
                                children: [
                                    {
                                        path: ":title",
                                        element: <EcosystemItem />,
                                    },
                                    /*{
                                        path: RoutesNames.Ecosystem
                                            .Perfectnodes,
                                        element: <PerfectNodes />,
                                    },
                                    {
                                        path: RoutesNames.Ecosystem
                                            .FastNProduct,
                                        element: <Fastnproduct />,
                                    },
                                    {
                                        path: RoutesNames.Ecosystem.Kalyswap,
                                        element: <Kalyswap />,
                                    },
                                    {
                                        path: RoutesNames.Ecosystem.KankouDao,
                                        element: <Kankoudao />,
                                    },
                                    {
                                        path: RoutesNames.Ecosystem.Kalysynthex,
                                        element: <Kalysynthex />,
                                    },
                                    {
                                        path: RoutesNames.Ecosystem.Exchange,
                                        element: <Exchanges />,
                                    },*/
                                ],
                            },
                            {
                                path: RoutesNames.Accounting.Index,
                                element: <AccountingBase />,
                                children: [
                                    {
                                        path: RoutesNames.Accounting.Balance,
                                        element: <Balances />,
                                    },
                                    {
                                        path: RoutesNames.Accounting.Fee,
                                        element: <Balances />,
                                    },
                                ],
                            },
                            {
                                path: RoutesNames.Moderation,
                                element: <Moderations />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Commercial,
                                element: <Commercial />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Point_of_sale,
                                element: <PointOfSale />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Sales_manager,
                                element: <SalesManager />,
                                index: true,
                            },
                            {
                                path: RoutesNames.ColdWallet,
                                element: <ColdWalletDetail />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Profil,
                                element: <ProfilForm />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Partners,
                                element: <Partner />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Business_dev,
                                element: <BusinessDev />,
                                index: true,
                            },
                            {
                                path: RoutesNames.MailModeration,
                                element: <MailModerations />,
                                index: true,
                            },
                            {
                                path: RoutesNames.BloquedUsers,
                                element: <BloquedUsers />,
                                index: true,
                            },
                            {
                                path: RoutesNames.TransactionModeration,
                                element: <TransactionModeration />,
                                index: true,
                            },
                            {
                                path: RoutesNames.Borrow,
                                element: <Borrowing />,
                                index: true,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default KalypayRoutes;
